<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="row">
        <div class="col-md-12">
          <div class="card-header">
            <h3 class="card-title"> Add Staff </h3>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6 connectedSortable">
            <div class="card">
              <div class="card-body">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                    <div>
                      <div class="form-group">
                        <label for="description">Username</label>
                        <input
          class="form-control"
          id="staff_unique_id"
          required
          v-model="staff_unique_id"
          name="staff_unique_id"
          placeholder="Username"
        />
                      </div>
                      <div class="form-group">
                        <label for="price">Password</label>
                        <input
                        type="password"
          class="form-control"
          id="password"
          required
          v-model="password"
          name="password"
          placeholder="Password"
        />
                      </div>
                       <div class="form-group">
                        <label for="description">Staff Name</label>
                        <input
          class="form-control"
          id="staffname"
          required
          v-model="staffname"
          name="staffname"
          placeholder="Staff Name"
        />
                      </div>
                      <div class="form-group">
                         <label>Staff Role</label>
                    <br>
<select v-model="roleId" class="form-control">
<option v-for="role in rolelist" :key="role.id" :value="role.id"> {{role.role_name}} </option>
</select>
                      </div>
                      <button @click="addStaff" class="btn btn-success">Submit</button>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {
  extends:defineComponent,
  components:{
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  name: "addstaff",
  data() {
    return {      
        id: null,
        company_id:this.$store.state.auth.user.data[0].company_id,
        user_id:this.$store.state.auth.user.data[0].id,
        staff_unique_id: "",
        password: "",
        staffname: "", 
        role_id: this.roleId,         
        published: false,      
        rolelist:[],
        name:'',
        submitted: false
    };
  },
  methods: {
    async getRolelist() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/getrole_list`, {
          company_id: this.company_id, 
          user_id: this.user_id,         
        })      
    this.name = response.data.data[0].role_name;      
    this.rolelist = response.data.data;
    this.roleId = response.data.data[0].id;      
    console.log(this.rolelist)
    },
    addStaff(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/staff_add_data', {
              company_id: this.company_id,
              user_id: this.user_id,
              staff_unique_id: this.staff_unique_id,
              password: this.password,
              name: this.staffname,
              role_id: this.roleId
              })
                .then(response => {   
                  this.showsuccessmsg(response.data.response);                  
                  console.log(response.data);                               
                  //this.$router.push({path:'/extrachargelist'})
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        },
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },    
    
   
  },
  mounted(){        
        this.getRolelist();        
    }
};
</script>

<style> 
  @import '../../assets/dist/css/custom.css'  
</style>